<template>
  <div>
    <b-card title="Configuration">
      <b-card-body>

        <b-row>
          <b-col
            v-for="(calculation, calculationIndex) in comparisons"
            :key="calculationIndex"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="calculation.title"
                    name="title"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <b-button
                    style="margin-top: 22px"
                    variant="outline-success"
                    @click="copyComparison(calculationIndex)"
                  >
                    Copy
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              label="Formula"
              label-for="formula"
            >
              <v-select
                v-model="calculation.formula"
                :options="formulas"
                :clearable="false"
                input-id="formula"
              />
            </b-form-group>

            <b-row
              v-for="(tariff, tariffIndex) in calculation.tariffs"
              :key="tariffIndex"
            >
              <b-col>
                <b-form-group
                  label="Breakpoint"
                  label-for="breakpoint"
                >
                  <b-form-input
                    id="breakpoint"
                    v-model="tariff.breakpoint"
                    name="breakpoint"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Price"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="tariff.price"
                    name="price"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-button
                  style="margin-top: 22px"
                  variant="outline-danger"
                  @click="removeTariff(calculationIndex, tariffIndex)"
                >
                  -
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col />
              <b-col />
              <b-col>
                <b-button
                  variant="outline-success"
                  @click="addTariff(calculationIndex)"
                >
                  +
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-button
              variant="outline-success"
              @click="addComparison"
            >
              +
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group
              label="Min count"
              label-for="minCount"
            >
              <b-form-input
                id="minCount"
                v-model="minCount"
                name="minCount"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Max count"
              label-for="maxCount"
            >
              <b-form-input
                id="maxCount"
                v-model="maxCount"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              style="margin-top: 22px"
              variant="outline-success"
              @click="calculate"
            >
              Calculate
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      v-if="Object.keys(chartjsData.data).length"
      no-body
    >
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            Income
          </b-card-title>
          <b-card-sub-title>Income between different invoicing methodologies</b-card-sub-title>
        </div>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <chartjs-component-line-chart
          :height="400"
          :chart-data="chartjsData.data"
          :options="chartjsData.options"
          :plugins="plugins"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from './ChartjsComponentLineChart.vue'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'

// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    ChartjsComponentLineChart,
    vSelect,
  },
  data() {
    return {
      minCount: 0,
      maxCount: 20000,
      formulas: [
        {
          value: 'Direct',
          label: 'Direct',
        },
        {
          value: 'Cumulative',
          label: 'Cumulative',
        },
      ],
      comparisons: [],
      chartjsData: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            mode: 'index',
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: chartColors.greyLightColor,
            titleFontColor: '#000',
            bodyFontColor: '#000',
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {},
      },
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const formData = JSON.parse(urlParams.get('form_data'))
    if (formData) {
      // if query string has prefilled form data then use this
      this.comparisons = formData
      return
    }
    this.addComparison()

    // EAS price list hack
    this.comparisons[0].tariffs[0].price = 0.49

    this.addTariff(0)
    this.comparisons[0].tariffs[1].breakpoint = 1001
    this.comparisons[0].tariffs[1].price = 0.241

    this.addTariff(0)
    this.comparisons[0].tariffs[2].breakpoint = 4001
    this.comparisons[0].tariffs[2].price = 0.097

    this.addTariff(0)
    this.comparisons[0].tariffs[3].breakpoint = 10000
    this.comparisons[0].tariffs[3].price = 0.082

    this.addTariff(0)
    this.comparisons[0].tariffs[4].breakpoint = 100000
    this.comparisons[0].tariffs[4].price = 0.053
  },
  methods: {
    addComparison() {
      this.comparisons.push({
        title: this.calculationTitle(),
        formula: {
          value: 'Cumulative',
          label: 'Cumulative',
        },
        tariffs: [],
      })
      this.addTariff(this.comparisons.length - 1)
    },
    addTariff(calculationIndex) {
      this.comparisons[calculationIndex].tariffs.push({
        breakpoint: 0,
        price: 0,
      })
    },
    calculate() {
      const comparisons = []
      this.comparisons.forEach(comparison => {
        comparisons.push({
          title: comparison.title,
          formula: comparison.formula.value,
          tariffs: comparison.tariffs,
        })
      })

      // add params to the query
      const query = '/invoicing/tryout?form_data='.concat(JSON.stringify(this.comparisons).toString())
      window.history.pushState(null, null, query)

      this.$http.post('/v1/invoicing/tryout', {
        min_count: this.minCount,
        max_count: this.maxCount,
        comparisons: comparisons,
      })
        .then(response => {
          const lineColors = [
            chartColors.lineChartDanger,
            chartColors.lineChartPrimary,
            chartColors.warningColorShade,
          ]

          this.chartjsData.data = {
            labels: [],
            datasets: [],
          }

          this.chartjsData.data.labels = response.data.labels
          const that = this
          response.data.datasets.forEach((dataset, index) => {
            const lineColor = lineColors[index]
            that.chartjsData.data.datasets.push({
              data: dataset.data,
              label: dataset.label,
              borderColor: lineColor,
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: lineColor,
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: lineColor,
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: chartColors.tooltipShadow,
            })
          })
        })
    },
    calculationTitle() {
      return `Calculation ${this.comparisons.length + 1}`
    },
    copyComparison(calculationIndex) {
      const calculation = JSON.parse(JSON.stringify(this.comparisons[calculationIndex]))
      calculation.title = this.calculationTitle()
      this.comparisons.push(calculation)
    },
    removeTariff(calculationIndex, tariffIndex) {
      this.comparisons[calculationIndex].tariffs.splice(tariffIndex, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
